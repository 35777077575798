function addError() {
  var elemDiv = document.createElement('div');
  var contDiv = document.createElement('div');
  contDiv.classList.add('container');
  contDiv.style.paddingTop = "10px";
  contDiv.style.width = "600px";
  contDiv.style.textAlign = "center";
  elemDiv.classList.add('alert');
  elemDiv.classList.add('alert-warning');
  elemDiv.innerHTML = 'This website is not compatible with Internet Explorer due to Microsoft no longer offering full support and updates on the browser. Please access this site using an alternative browser (Chrome, Edge, Firefox etc)';
  contDiv.appendChild(elemDiv);
  document.body.appendChild(contDiv);
}


if (navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1){
  document.addEventListener('DOMContentLoaded', addError, false);
}
